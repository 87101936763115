<template>
  <div class="blog-likes">
    <div class="card-panel z-depth-1">
      <div class="">
        <span class="black-text">
          <a
            @click="actionLike()"
            ref="liked_btn"
            class="btn-floating btn-small waves-effect waves-light red pulse"
            v-if="liked_by_user">
            <i class="material-icons">star</i>
          </a>
          <a
            @click="actionLike()"
            class="btn-floating btn-small waves-effect waves-light red"
            v-else>
            <i class="material-icons">exposure_plus_1</i>
          </a>
          <span v-if="likes > 0">{{ like_text }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
export default {
  props: ['likes', 'liked_by_user', 'id'],
  components: {
    //
  },
  data() {
    return {
      //
    };
  },
  computed: {
    like_text() {
      let string = '',
        stringSuffix = 'loved this article';
      if (this.liked_by_user === false) {
        string =  `${this.likes} ${this.likes > 1 ? 'people' : 'person'} ${stringSuffix}`;
      }
      else {
        let likesExceptYou = this.likes - 1;
        string = `You ${likesExceptYou > 0 ? `and ${likesExceptYou} ${likesExceptYou > 1 ? `people` : `person`}` : ''} ${stringSuffix}`;
      }
      return string;
    },
  },
  methods: {
    actionLike() {
      this.logLike(`/blog/article/${this.id}`, response => {
        if (response.status) {
          this.liked_by_user = response.liked_by_user;
          this.likes = response.likes;
        }
      })
    },
  },
  created() {
    //
  },
  mounted() {
    // console.log(this.likes);
    // console.log(this.liked_by_user);
    // console.log(this.id);
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.btn-floating {
  margin-right: 5px;
}
</style>
