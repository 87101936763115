<template>
  <div class="error height-fixer container center-align">
    <u-animate-container>
      <u-animate
        name="fadeInDown"
        :delay="`0s`"
        duration="1s"
        :iteration="1"
        :offset="0"
        animateClass="animated"
        :begin="false">
        <h3>Hmm .. I think it shouldn't be like this</h3>
      </u-animate>
      <u-animate
        name="fadeInDown"
        :delay="`.15s`"
        duration="1s"
        :iteration="1"
        :offset="0"
        animateClass="animated"
        :begin="false">
        <p>In this case, this would happen ONLY when something is error in the server.</p>
      </u-animate>
      <u-animate
        name="fadeInDown"
        :delay="`.3s`"
        duration="1s"
        :iteration="1"
        :offset="0"
        animateClass="animated"
        :begin="false">
        <p>If you tried again and still get the same, feel free to inform me to fix it.</p>
      </u-animate>
      <u-animate
        name="fadeInUp"
        :delay="`.45s`"
        duration="1s"
        :iteration="1"
        :offset="0"
        animateClass="animated"
        :begin="false">
        <img src="@/assets/error-img.png" alt="Error" />
      </u-animate>
      <u-animate
        name="fadeInUp"
        :delay="`.75s`"
        duration="1s"
        :iteration="1"
        :offset="0"
        animateClass="animated"
        :begin="false">
        <h6>And ... that's it!</h6>
      </u-animate>
    </u-animate-container>
  </div>
</template>

<script>
export default {
  props: [],
  components: {
    //
  },
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
img {
  display: block;
  margin: 0 auto;
  padding: 33px;
  max-width: 100%;
  max-height: 400px;
}
</style>
