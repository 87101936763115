<template>
  <div class="quote blue-grey z-depth-0">
    <u-animate-container>
      <div class="container">
        <u-animate
          name="fadeInUp"
          delay="0s"
          duration="1s"
          :iteration="1"
          :offset="100"
          animateClass="animated"
          :begin="false">
          <h4>{{ header }}</h4>
        </u-animate>
        <u-animate
          name="fadeInUp"
          delay=".25s"
          duration="1s"
          :iteration="1"
          :offset="100"
          animateClass="animated"
          :begin="false">
          <h6>{{ text }}</h6>
        </u-animate>
      </div>
    </u-animate-container>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
export default {
  props: ['header', 'text'],
  components: {
    //
  },
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.quote {
  margin-top: 40px;
  padding: 25px 25px 45px;
  text-align: center;
  color: #fff;
}
</style>
