<template>
  <div class="me container">
    <div class="row me-row" ref="me_row">
      <div class="col m8 s12">
        <div class="me-intro center-align" ref="me_intro">
          <h4>Your passion is all what you need to be special!</h4>
          <article>
            <p>Follow your heart, listen to your inner voice, stop caring about what others think.</p>
            <p>My name is Nael Dahman, {{ age }} years, studied Banking and Financial Sciences in Aleppo University for 5 years since end of 2012. During my academic years, I worked hard to prove to myself that my passion for web development was my dream that I believed in.</p>
            <p>Currently, I live in Erbil, Kurdistan Iraq.</p>
          </article>
        </div>
      </div>
      <div class="col m4 s6 push-s3">
        <div class="me-img" ref="me_img">
          <img src="@/assets/me.jpg" class="z-depth-1 hoverable" alt="Me" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
export default {
  data() {
    return {
      //
    };
  },
  computed: {
    browser() {
      return this.$browserDetect.meta.name;
    },
    excludedBrowsers() {
      return ['Edge'];
    },
    age() {
      let month = 7,
        Year = 1994,
        age = this.year - Year;
      //
      if (new Date().getMonth() < month) {
        age--;
      }
      return age;
    },
  },
  methods: {
    scroll() {
      let st = document.documentElement.scrollTop,
        scrollLimitStart = 200,
        elementPositionStart = 125,
        elementPositionFn = parseInt((st / 1.67) - scrollLimitStart - elementPositionStart),
        elementPosition = elementPositionFn <= 0 ? elementPositionFn : 0,
        elementOpacity = ((-33 / elementPosition) < 1 && (-33 / elementPosition) !== -Infinity)
                        ? (-33 / elementPosition) : 1,
        meIntro = this.$refs.me_intro,
        meImg = this.$refs.me_img,
        meRow = this.$refs.me_row;
      //
      // console.log(st);
      if (st >= scrollLimitStart) {
        // console.log(elementOpacity);
        meImg.style.right = `${elementPosition}px`;
        meIntro.style.left = `${elementPosition}px`;
        meRow.style.opacity = elementOpacity;
      }
    },
    injectAnimatableClass() {
      let meIntro = document.querySelector('.me-intro'),
        meImg = document.querySelector('.me-img'),
        meRow = document.querySelector('.me-row');
      //
      if (meIntro !== null) {
        meIntro.classList.add('animatable');
        meImg.classList.add('animatable');
        meRow.classList.add('animatable');
      }
    },
  },
  created() {
    //
  },
  mounted() {
    if (!this.excludedBrowsers.includes(this.browser)) {
      window.addEventListener('scroll', this.scroll);
      this.injectAnimatableClass();
    }
  },
  destroyed() {
    if (!this.excludedBrowsers.includes(this.browser)) {
      window.removeEventListener('scroll', this.scroll);
      this.injectAnimatableClass();
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.me {
  padding: 50px 0 0px;
  //
  .me-row.animatable {
    opacity: .2;
  }
  //
  .me-img {
    img {
      display: block;
      width: 100%;
      @include radius(100%);
    }
    //
  }
  //
  .me-img, .me-intro {
    position: relative;
    //
    &.animatable {
      &.me-img {
        right: -210px;
      }
      &.me-intro {
        left: -210px;
      }
    }
  }
}
</style>
