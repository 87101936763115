/* eslint-disable no-unused-vars */
export default {
  beforeCreate() {
    //
  },
  created() {
    //
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      this.startProgress();
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          //behavior: 'smooth'
          scrollBehavior(to, from, savedPosition) {
            return savedPosition ?? { x: 0, y: 0 };
          },
        });
      }, 1);
      next();
    });
    //
    this.$router.afterEach((to) => {
      let fullPath = this.$router.currentRoute.fullPath;
      this.isHome = fullPath === '/';
      //console.log(fullPath);
      // console.log(to);
      if (to.name === "Error") {
        // it means that the requested route is not defined or maybe it is bad url.
        // we should finish the Progressbar
        this.finishProgress();
      }
    });
    let fullPath = this.$router.currentRoute.fullPath;
    this.isHome = fullPath === '/';
  },
  data() {
    return {
      isHome: '',
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    api() {
      let link;
      if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
        link = 'http://localhost/cv/api/api.php';
      }
      else {
        link = 'http://api.ar-economist.com/naeldahman/api.php';
      }
      //
      return link;
    },
    resourcesBaseURL() {
      let link;
      if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
        link = 'http://localhost/cv/storage/';
      }
      else {
        link = 'http://nael.ar-economist.com/storage/';
      }
      //
      return link;
    },
    baseTitleSuffix() {
      return 'Mohammad Nael Dahman';
    },
    langs() {
      return ['en'];
    },
  },
  methods: {
    startProgress() {
      this.$Progress.start();
    },
    finishProgress(afterSeconds = 750) {
      let _this = this;
      setTimeout(() => {
        _this.$Progress.finish();
      }, afterSeconds);
    },
    isNumber(event, message) {
      if (!/\d/.test(event.key) && (event.key !== "." || /\./.test(message))) {
        return event.preventDefault();
      }
    },
    clog(param) {
      console.log(param);
    },
    updateTitlePage(title) {
      if (title) {
        document.title = title + ' :: ' + this.baseTitleSuffix;
      }
      else {
        document.title = this.baseTitleSuffix;
      }
    },
    lang_json(obj, lang) {
      obj = typeof obj !== 'object' ? JSON.parse(obj) : obj;
      return obj.lang === undefined ? obj[lang] : obj.lang;
    },
    storage(url) {
      return this.resourcesBaseURL + url;
    },
    youtubeEmbedGenerator(youtube_id) {
      return `<iframe width="100%" height="315" src="https://www.youtube.com/embed/${youtube_id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    },
    checkProperties(obj) {
      let arr = [];
      if (Object.keys(obj).length === 0) { return true; }
      else {
        for (let key in obj) {
          arr.push(obj[key] !== undefined && obj[key] !== null && obj[key] !== "");
        }
        return arr.includes(false);
      }
    },
    slugify(text) {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w-]+/g, '')       // Remove all non-word chars
        .replace(/--+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
    },
    duration(time, divideBy) {
      time++;
      let t = (time / divideBy);
      //
      if (t > parseInt(t)) {
        t = t - parseInt(t);
      }
      t = Math.min(t, 1);
      //
      return t;
    },
    logLike(route, callback = null) {
      let _this = this;
      this.$axios.post(this.api, {
        type: 'log-like',
        route: route,
      })
      .then(response => {
        let data = response.data;
        if (callback !== null) { callback(data); }
        return data;
      });
    },
  },
};