<template>
  <div class="blog-card">
    <div class="card hoverable" v-bind="{'data-id-blog': id, 'data-id-category': id_category}">
      <div class="card-image">
        <img :src="image" /> <!-- https://picsum.photos/id/655/850/450 -->
        <span class="card-title" :class="{'is-rtl': isArabic}">{{ title }}</span>
        <router-link v-if="briefing"
          class="btn-floating btn-large halfway-fab waves-effect waves-light red"
          :to="`/blog/article/${id}`">
          <i class="material-icons">link</i>
        </router-link>
        <div v-else class="fixed-action-btn toolbar click-to-toggle">
          <a class="btn-floating btn-large red pulse">
            <i class="large material-icons">share</i>
          </a>
          <ul>
            <li>
              <a
                class="btn-floating"
                data-position="top" data-tooltip="Share via WhatsApp"
                :href="articleFABbutton('whatsapp')"
                target="_blank">
                <i class="fab fa-whatsapp"></i>
              </a>
            </li>
            <li>
              <a
                class="btn-floating"
                data-position="top" data-tooltip="Share via Viber"
                :href="articleFABbutton('viber')"
                target="_blank">
                <i class="fab fa-viber"></i>
              </a>
            </li>
            <li>
              <a
                class="btn-floating"
                data-position="top" data-tooltip="Share via Telegram"
                :href="articleFABbutton('telegram')"
                target="_blank">
                <i class="fab fa-telegram"></i>
              </a>
            </li>
            <li>
              <a
                class="btn-floating"
                data-position="top" data-tooltip="Share as Facebook post"
                :href="articleFABbutton('facebook')"
                target="_blank">
                <i class="fab fa-facebook-square"></i>
              </a>
            </li>
            <li v-if="false">
              <a
                class="btn-floating"
                data-position="top" data-tooltip="Share via Messenger"
                :href="articleFABbutton('messenger')"
                target="_blank">
                <i class="fab fa-facebook-messenger"></i>
              </a>
            </li>
            <li>
              <a
                class="btn-floating"
                data-position="top" data-tooltip="Tweet via Twitter"
                :href="articleFABbutton('twitter')"
                target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-content">
        <article :class="{'is-rtl': isArabic}" lang="ar" v-html="text.replace(/(?:\r\n|\r|\n)/g, '<br />')"></article>
        <hr />
        <ul class="article-details">
          <li>
            <i class="material-icons">date_range</i>
            <span>{{ time | moment('from', 'now') }}</span>
          </li>
          <li>
            <i class="material-icons">remove_red_eye</i>
            <span>{{ $humanize.numberFormat(visits, 0) }}</span>
          </li>
          <li v-if="tags.length > 0">
            <i class="material-icons">bookmark_border</i>
            Tags: {{ tags.join(", ") }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
export default {
  props: ['data', 'briefing'],
  components: {
    //
  },
  data() {
    return {
      //
    };
  },
  computed: {
    title() {
      return this.data.article.blog_title;
    },
    id() {
      return this.data.article.id_blog;
    },
    id_category() {
      return this.data.article.blog_id_category;
    },
    image() {
      return this.storage(`blog/${this.data.article.blog_image}`);
    },
    tags() {
      return JSON.parse(this.data.article.blog_tags);
    },
    text() {
      let text;
      if (this.briefing) {
        text = this.$humanize.truncatewords(this.data.article.blog_text, 37);
      }
      else {
        text = this.data.article.blog_text;
      }
      return text;
    },
    time() {
      return this.data.article.blog_datetime * 1000;
    },
    visits() {
      return this.data.visits;
    },
    isArabic() {
      let regex = /[\u0600-\u06FF]/;
      return regex.test(this.text);
    },
  },
  methods: {
    articleFABbutton(type) {
      let href, link = location.href;
      switch (type) {
        case 'twitter':
          href = `https://twitter.com/intent/tweet?text=${this.title}&url=${link}`;
          break;
        case 'whatsapp':
          href = `whatsapp://send?text=${this.title} ${link}`;
          break;
        case 'facebook':
          href = `https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=${link}&display=popup&ref=plugin&src=share_button`;
          break;
        case 'viber':
          href = `viber://forward?text=${this.title} ${link}`;
          break;
        case 'telegram':
          href = `https://telegram.me/share/url?url=${link}&text=${this.title}`;
          break;
        case 'messenger':
          href = ``;
          break;
        default:
          href = ``;
          break;
      }
      //
      return href;
    },
  },
  created() {
    //
  },
  mounted() {
    if (this.briefing === undefined) {
      //
      let fab = document.querySelectorAll('.fixed-action-btn');
      let tooltips = document.querySelectorAll('[data-tooltip]');
      //
      M.Tooltip.init(tooltips);
      M.FloatingActionButton.init(fab, {
        direction: 'left',
        toolbarEnabled: true,
      });
    }
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.is-rtl {
  direction: rtl;
  text-align: left;
}
//
.blog-card {
  .card {
    .card-image {
      position: relative;
      //
      .card-title {
        background: linear-gradient(to top, #111, transparent);
        width: 100%;
        max-width: auto;
      }
      //
      img {
        min-height: 250px;
        object-fit: cover;
        background: center center no-repeat url('/assets/lazyload.gif');
      }
      //
      .btn-floating.btn-large.halfway-fab {
        bottom: -15px;
      }
      //
      .fixed-action-btn {
        position: absolute;
        bottom: -15px;
        z-index: 2;
        //
        ul {
          top: 50%;
          right: 0;
          width: 100%;
          //
          li .btn-floating {
            border-radius: 0;
          }
        }
      }
    }
    //
    .card-content {
      .article-details {
        overflow: hidden;
        //
        .material-icons {
          position: relative;
          bottom: -4px;
          margin: 0 5px;
          font-size: 17px;
        }
        //
        > li {
          color: #888;
          font-size: 12px;
          float: left;
          margin: 0 5px;
          position: relative;
          //
          &::after {
            content: '';
            position: absolute;
            top: 6px;
            right: -8px;
            width: 1px;
            height: 100%;
            background-color: #888;
          }
          //
          &:last-child {
            &::after {
              position: static;
            }
          }
        }
      }
      //
      article {
        font-size: 15px;
        //
        &.is-rtl {
          direction: rtl;
          text-align: right;
        }
      }
    }
  }
  //
}
</style>
