<template>
  <div class="experiences container">
    <u-animate-container>
      <div class="center-align">
        <u-animate
          name="fadeInUp"
          delay="0s"
          duration="1s"
          :iteration="1"
          :offset="100"
          animateClass="animated"
          :begin="false">
          <h2>My Experiences</h2>
        </u-animate>
        <u-animate
          name="fadeInUp"
          delay="0s"
          duration="1s"
          :iteration="1"
          :offset="100"
          animateClass="animated"
          :begin="false">
          <h6>Failure is the condiment that gives success its flavor</h6>
          <hr />
        </u-animate>
      </div>
      <div class="row">

        <div
          v-for="(item, i) in data"
          v-bind:key="i"
          class="col xl3 l4 m6 s12 s-modified">
          <u-animate
            name="fadeInUp"
            :delay="`${duration(i, 4)}s`"
            duration="1s"
            :iteration="1"
            :offset="150"
            animateClass="animated"
            :begin="false">
            <app-experience-card v-bind="{item: item}" />
          </u-animate>
        </div>

      </div>
    </u-animate-container>
  </div>
</template>

<script>
import ExperienceCard from '@/components/home/ExperiencesCard.vue';
export default {
  props: ['data'],
  components: {
    'app-experience-card': ExperienceCard,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
</style>
