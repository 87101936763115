<template>
  <div class="navbar-fixed">
    <nav class="teal darken-4">
      <div class="nav-wrapper animated fadeInDownBig">
        <router-link to="/" class="brand-logo center">
          <img src="@/assets/mnd-transparent-whited.png" alt="MND" />
        </router-link>
        <ul id="nav-mobile" class="left hide-on-med-and-down">
          <li>
            <a v-if="isHome" href="javascript://" class="route-home" @click="scrollTo('me')">Me</a>
            <router-link v-else to='/' class='route-home'>Home</router-link>
          </li>
          <li>
            <a v-if="isHome" href="javascript://" class="route-home" @click="scrollTo('career')">Career</a>
            <router-link v-else to='/career' class='route-home'>Career</router-link>
          </li>
          <li>
            <a v-if="isHome" href="javascript://" class="route-home" @click="scrollTo('experiences')">Experiences</a>
            <router-link v-else to='/experiences' class='route-home'>Experiences</router-link>
          </li>
          <li>
            <a v-if="isHome" href="javascript://" class="route-home" @click="scrollTo('portfolio')">Portfolio</a>
            <router-link v-else to='/portfolio' class='route-home'>Portfolio</router-link>
          </li>
          <li>
            <router-link to='/blog' class='route-homee'>Blog</router-link>
          </li>
        </ul>
        <ul id="nav-mobile" class="right hide-on-med-and-down">
          <li>
            <a v-if="isHome" href="javascript://" class="route-home" @click="scrollTo('references')">References</a>
            <router-link v-else to='/references' class='route-home'>References</router-link>
          </li>
          <li>
            <a v-if="isHome" href="javascript://" class="route-home" @click="scrollTo('technologies-carousel')">Loved my website?</a>
          </li>
          <li>
            <router-link to='/contact-me'>Drop message</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    scrollTo(section) {
      if (this.$router.currentRoute.path !== '/') {
        return;
      }
      let st = 0;
      switch(section) {
        case 'me':
          st = document.querySelector('.me').offsetTop - 100;
          break;
        case 'career':
          st = document.querySelector('.career').offsetTop - 17;
          break;
        case 'experiences':
          st = document.querySelector('.experiences').offsetTop - 80;
          break;
        case 'portfolio':
          st = document.querySelector('.portfolio').offsetTop - 80;
          break;
        case 'references':
          st = document.querySelector('.references').offsetTop - 17;
          break;
        case 'technologies-carousel':
          st = document.querySelector('.technologies-carousel').offsetTop - 60;
          break;
      }
      //
      window.scrollTo({
        top: st,
        left: 0,
        behavior: 'smooth'
      });
    },
  },
  mounted() {
    //
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_includes.scss';
//
.brand-logo {
  img {
    display: block;
    height: 42px;
  }
}

ul#nav-mobile {
  a {
    & {
      outline: none;
    }
  }
}
</style>
