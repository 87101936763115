<template>
  <div class="technologies-carousel container">
    <u-animate-container>
      <u-animate
        name="fadeInUp"
        delay="0s"
        duration="1s"
        :iteration="1"
        :offset="225"
        animateClass="animated"
        :begin="false">
        <h2 class="center-align">Loved my website?</h2>
      </u-animate>
      <u-animate
        name="fadeInUp"
        delay="0s"
        duration="1s"
        :iteration="1"
        :offset="225"
        animateClass="animated"
        :begin="false">
        <h6 class="center-align">Here are the tools that I'd used to build my amazing website:</h6>
      </u-animate>
      <u-animate
        name="fadeInUp"
        delay=".25s"
        duration="1s"
        :iteration="1"
        :offset="125"
        animateClass="animated"
        :begin="false">
        <vue-owl-carousel
          class="carousel"
          v-bind="{
            responsive: responsive,
            autoplay: false,
            autoplaySpeed: 750,
          }">
          <div
            v-for="(slide, i) in slides"
            v-bind:key="i"
            class="slide">
            <div class="slide-img">
              <a :href="slide.slide_link">
                <img :src="storage(`slider/${slide.slide_image}`)" :alt="slide.slide_title" />
              </a>
            </div>
            <div class="slide-text">
              <p>{{ slide.slide_text }}</p>
            </div>
            <div class="clearfix"></div>
          </div>
        </vue-owl-carousel>
      </u-animate>
    </u-animate-container>
  </div>
</template>

<script>
export default {
  props: ['data'],
  components: {
    //
  },
  data() {
    return {
      responsive: {
        0: {
          items: 1,
        },
        // 481: {
        //   items: 2,
        // },
        // 768: {
        //   items: 3,
        // },
        // 993: {
        //   items: 3,
        // },
        // 1184: {
        //   items: 1,
        // },
      },
      slides: this.data,
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_includes.scss';
//
.technologies-carousel {
  padding: 30px 0;
  //
  .carousel {
    margin: 30px 0;
    height: auto;
    //
    .slide {
      //margin: 0 25px;
      text-align: center;
      padding: 0 25px;
      //
      .slide-img {
        //
        img {
          display: inline-block;
          width: auto;
          max-height: 125px;
          margin: 0 auto;
          padding: 5px;
        }
      }
      //
      .slide-text {
        padding: 10px;
      }
      //
      @media (max-width: 600px) {
        .slide-img, .slide-text {
          width: 100%;
        }
      }
    }
  }
}
</style>
