<template>
  <div class="portfolio container">
    <u-animate-container>
      <u-animate
        name="fadeInUp"
        delay="0s"
        duration="1s"
        :iteration="1"
        :offset="150"
        animateClass="animated"
        :begin="false">
        <h2 class="center-align">Want to see what I've achieved?</h2>
      </u-animate>
      <u-animate
        name="fadeInUp"
        delay=".33s"
        duration="1s"
        :iteration="1"
        :offset="150"
        animateClass="animated"
        :begin="false">
        <h6 class="center-align">I have been and still be proud of the completion of these projects. Each project has a distinct, special and unique story.</h6>
      </u-animate>
      <div class="">
        <div class="row">
          <u-animate
            name="fadeInUp"
            delay="0s"
            duration="1s"
            :iteration="1"
            :offset="200"
            animateClass="animated"
            :begin="false">
            <ul class="tabs portfolio-filter">
              <li class="tab col">
                <a href="javascript://" class="active" data-category="*">All</a>
              </li>
              <li class="tab col" v-for="(category, i) in categories" v-bind:key="i">
                <a href="javascript://" :data-category="`.category-${category.id_portfolio_category}`">
                  {{ title(category) }}
                </a>
              </li>
            </ul>
          </u-animate>
          
          <div class="projects">
            <div v-for="(project, i) in data" v-bind:key="i"
              :data-category="project.project_id_category"
              class="col xl3 l4 m6 s12 s-modified project">
              <u-animate
                name="jackInTheBox"
                :delay="`${duration(i, 4)}s`"
                duration="1s"
                :iteration="1"
                :offset="225"
                animateClass="animated"
                :begin="false">
                  <app-card v-bind="{data: project}" />
              </u-animate>
            </div>
          </div>

        </div>
      </div>
    </u-animate-container>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
export default {
  props: ['categories', 'data'],
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    title(item) {
      return item.portfolio_category_title;
    },
    slug(item) {
      return this.slugify(this.title(item));
    },
  },
  created() {
    //
  },
  mounted() {
    let tabs = document.querySelectorAll('.tabs');
    M.Tabs.init(tabs);
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.portfolio-filter {
  margin: 15px 0;
}
</style>
