<template>
  <div class="social-contact teal darken-1 z-depth-0">
    <u-animate-container>
      <div class="container">
        <h4>Are you interested in having a project for you?</h4>
        <h6>I'm very excited to write a success story with you.</h6>
        <ul class="social-items">
          <li v-for="(item, i) in social" v-bind:key="i">
            <img
              @click="activeItem = item"
              :src="storage(`social/${item.social_image}`)"
              :alt="item.social_title"
              class='tooltipped modal-trigger'
              data-position="top"
              data-target="socialModal"
              v-bind="{'data-tooltip': item.social_title}" />
          </li>
        </ul>
      </div>
    </u-animate-container>
    <!-- Modal Structure -->
    <div id="socialModal" class="modal bottom-sheet">
      <div class="modal-content">
        <h4 class="modal-header">{{ activeItem.social_title }}</h4>
        <p v-if="false">{{ activeItem.social_value }}</p>
        <div class="social-tools">
          <div v-if="activeItem.social_title === 'WhatsApp'">
            <p>Click on the icon to chat with me via</p>
            <a :href="`https://wa.me/${activeItem.social_value}`" target="_blank">
              <img :src="storage('social/whatsapp-2.png')" alt="WhatsApp" />
            </a>
          </div>
          <div v-else-if="activeItem.social_title === 'Viber'">
            <p>Click on the icon to chat with me via</p>
            <a :href="`viber://contact?number=${activeItem.social_value}`">
              <img :src="storage('social/viber-2.png')" alt="Viber" />
            </a>
          </div>
          <div v-else-if="activeItem.social_title === 'Telegram'">
            <p>Click on the icon to chat with me via Telegram</p>
            <a href="https://t.me/naeldahman" target="_blank"> <!-- tg://resolve?domain=naeldahman -->
              <img :src="storage('social/telegram.png')" alt="Telegram" />
            </a>
          </div>
          <div v-else-if="activeItem.social_title === 'Local phone'">
            <p>Click on the icon to call me locally</p>
            <a :href="`tel:+${activeItem.social_value}`">
              <img :src="storage('social/phone.png')" alt="Local phone" />
            </a>
          </div>
          <div v-else-if="activeItem.social_title === 'Outlook'">
            <p>Click on the icon to send me an email</p>
            <a :href="`mailto:${activeItem.social_value}`">
              <img :src="storage('social/outlook-2019.png')" alt="Viber" />
            </a>
          </div>
          <div v-else-if="activeItem.social_title === 'Skype'">
            <p>Click on the icon to chat with me via</p>
            <a :href="`skype:live:nael_d_2?chat`">
              <img :src="storage('social/skype-2.png')" alt="Skype" />
            </a>
          </div>
          <div v-else-if="activeItem.social_title === 'Facebook'">
            <p>Click in the icon to navigate directly to my official page</p>
            <a :href="activeItem.social_value" target="_blank">
              <img :src="storage('social/facebook.png')" alt="Facebook" />
            </a>
          </div>
          <div v-else-if="activeItem.social_title === 'YouTube'">
            <p>Click on the icon to navigate directly to my official YouTube channel</p>
            <a :href="activeItem.social_value" target="_blank">
              <img :src="storage('social/youtube-2.png')" alt="YouTube" />
            </a>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a href="javascript://" class="modal-close waves-effect waves-green btn-flat">Close</a>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
export default {
  props: [],
  components: {
    //
  },
  data() {
    return {
      activeItem: {},
      social: [],
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    let tooltips = document.querySelectorAll('.tooltipped'),
      modal = document.querySelectorAll('.modal');
    //
    M.Tooltip.init(tooltips);
    M.Modal.init(modal);
    //
    let _this = this;
    this.$axios.get(this.api, {
      params: {
        'type': 'get-social'
      }
    })
    .then(response => {
      let data = response.data;
      this.social = data.social;
    });
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.social-contact {
  padding: 25px;
  text-align: center;
  color: #fff;
  //
  .social-items {
    overflow: hidden;
    margin: 0 auto;
    padding: 5px;
    margin-top: 20px;
    @include fitContent;
    //
    > li {
      float: left;
      width: 75px;
      padding: 10px;
      //
      img {
        // width: 100%;
        margin: 0 auto;
        max-height: 45px;
        display: block;
        cursor: pointer;
      }
    }
  }
  //
  .modal {
    .modal-content {
      color: $main_color;
      //
      .social-tools {
        img {
          display: inline-block;
          max-height: 45px;
        }
        //
      }
    }
  }
}
</style>
