<template>
  <u-animate-container>
    <div class="reference-card z-depth-2">
      <div class="image">
        <img :src="image" :alt="fullname" />
      </div>
      <div class="header">
        <h5>{{ fullname }}</h5>
        <h6>{{ jobTitle }}</h6>
      </div>
      <article>
        <p>{{ jobDescription }}</p>
      </article>
      <hr />
      <ul class="contacting">
        <li v-if="phones.length > 0">
          <i class="material-icons">phone</i>
          <ul class="sub-ul">
            <li v-for="(phone, i) in phones" v-bind:key="i">{{ phone }}</li>
          </ul>
        </li>
        <li v-if="emails.length > 0">
          <i class="material-icons">mail</i>
          <ul class="sub-ul">
            <li v-for="(email, i) in emails" v-bind:key="i">
              <a :href="`mailto:${email}`">{{ email }}</a>
            </li>
          </ul>
        </li>
        <li v-if="websites.length > 0">
          <i class="material-icons">computer</i>
          <ul class="sub-ul">
            <li v-for="(website, i) in websites" v-bind:key="i">
              <a :href="website" target="_blank">{{ website }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </u-animate-container>
</template>

<script>
export default {
  props: ['item'],
  data() {
    return {
      //
    };
  },
  computed: {
    fullname() {
      return this.item.reference_fullname;
    },
    image() {
      return this.storage(`referencez/${this.item.reference_image}`);
    },
    jobTitle() {
      return this.item.reference_job_title;
    },
    jobDescription() {
      return this.item.reference_job_description;
    },
    phones() {
      let phones = JSON.parse(this.item.reference_phones);
      return phones;
    },
    emails() {
      let emails = JSON.parse(this.item.reference_emails);
      return emails;
    },
    websites() {
      let websites = JSON.parse(this.item.reference_websites);
      return websites;
    },
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
$shadow_image: #ffebee;
//
.reference-card {
  border: 1px solid white;
  background-color: #fff;
  text-align: center;
  padding: 30px 10px;
  margin: 15px 0;
  position: relative;
  bottom: 0;
  @include transitionDuration(.2s);
  @include radius(5px);
  //
  &:hover {
    bottom: 5px;
    //
    .image {
      box-shadow: 0 0 0 12px $shadow_image;
      //
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        border: 7px solid white;
        background-color: transparent;
        z-index: 0;
        @include transitionDuration(.5s);
        @include square(145px);
        @include radius(50%);
        @include transform(translate(-50%, -50%) scale(1.15));
      }
    }
  }
  //
  .image {
    width: 125px;
    margin: 0 auto;
    box-shadow: 0 0 0 7px $shadow_image;
    //overflow: hidden;
    position: relative;
    @include transitionDuration(.2s);
    @include radius(50%);
    //
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      border: 3px solid silver;
      background-color: transparent;
      z-index: 0;
      @include transitionDuration(.2s);
      @include square(145px);
      @include radius(50%);
      @include transform(translate(-50%, -50%) scale(.75));
    }
    //
    img {
      display: block;
      width: 100%;
      position: relative;
      z-index: 1;
      @include radius(50%);
    }
  }
  //
  .header {
    h5 {
      color: orange;
    }
  }
  //
  .contacting {
    text-align: left;
    //
    > li {
      border-bottom: 1px solid #fce4ec;
      padding: 10px 15px 10px 50px;
      position: relative;
      //
      i {
        display: inline-block;
        margin: 0 5px;
        position: absolute;
        top:  7px;
        left: 10px;
      }
      //
      .sub-ul {
        display: inline-block;
        position: relative;
        width: 100%;
        // bottom: 7px;
        // left: 7px;
        //
        li {
          text-align: center;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
