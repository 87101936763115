<template>
  <div class="blog-categories">
    <div class="card">
      <div class="card-content ">
        <span class="card-title">Categories</span>
        <app-loading :size="40" v-if="isLoading" />
        <ul v-else class="categories">
          <li v-for="(category, i) in data" v-bind:key="i" lang="ar">
            <u-animate
              name="fadeInUp"
              :delay="`${duration(i, data.length)}s`"
              :duration="`1s`"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="false">
              <router-link :to="`/blog/category/${category.id_blog_category}`">
                <span>{{ category.blog_category_title }}</span>
                <span class="right new badge" data-badge-caption="">{{ category.counter }}</span>
              </router-link>
            </u-animate>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
export default {
  props: ['data', 'isLoading'],
  components: {
    //
  },
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.categories {
  li {
    //
    &:last-child a {
      border-bottom: 0 none;
    }
    //
    a {
      display: block;
      padding: 10px 2px;
      border-bottom: 1px solid silver;
      color: #555;
      font-size: 16px;
      outline: none;
      @include transitionDuration(.2s);
      //
      &:hover {
        color: rgb(241, 136, 136);
      }
    }
  }
}
</style>
