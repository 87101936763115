<template>
  <u-animate-container>
    <div class="references container">
      <div class="center-align">
        <u-animate
          name="fadeInUp"
          delay="0s"
          duration="1s"
          :iteration="1"
          :offset="150"
          animateClass="animated"
          :begin="false">
          <h2>References</h2>
        </u-animate>
        <u-animate
          name="fadeInUp"
          delay=".25s"
          duration="1s"
          :iteration="1"
          :offset="150"
          animateClass="animated"
          :begin="false">
          <h6>A People credited with developing my career in web development and I am proud to know them and work with them. Really, I owe them with all love and respect.</h6>
        </u-animate>
      </div>
      <div class="row">
        <div
          v-for="(item, i) in data"
          v-bind:key="i"
          class="col s12 m6 l6 xl4">
          <u-animate
            name="fadeInUp"
            :delay="`${duration(i)}s`"
            duration="1s"
            :iteration="1"
            :offset="225"
            animateClass="animated"
            :begin="false">
            <app-reference-card v-bind="{item: item}" />
          </u-animate>
        </div>
      </div>
    </div>
  </u-animate-container>
</template>

<script>
import ReferenceCard from '@/components/home/ReferencesCard.vue';
export default {
  props: ['data'],
  components: {
    'app-reference-card': ReferenceCard,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.references {
  padding: 50px 0 25px;
}
</style>
