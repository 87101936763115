<template>
  <div class="height-fixer career">
    <app-loading v-if="isLoading" />
    <app-career v-bind="{data: career}" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Career from '@/components/home/Career.vue';
export default {
  props: [],
  components: {
    'app-career': Career,
  },
  data() {
    return {
      career: [],
      isLoading: true,
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    let _this = this;
    this.$axios.get(this.api, {
      params: {
        'type': 'get-career',
      }
    })
    .then(response => {
      let data = response.data;
      this.career = data.career;
      this.isLoading = false;
    })
    .catch(() => {
      //
    })
    .finally(() => {
      this.finishProgress();
    })
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.career {
  padding: 35px 0;
}
</style>
