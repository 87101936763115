/* eslint-disable no-unused-vars */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import mixin from './mixin.js';
import axios from 'axios';
import humanize from 'humanize';
import '@/assets/animate.css';

import VueProgressBar from 'vue-progressbar';
import VueTypedJs from 'vue-typed-js';
import VueWow from 'vue-wow';
import VueCarousel from 'vue-carousel';
import VueOwlCarousel from 'vue-owl-carousel';
import browserDetect from "vue-browser-detect-plugin";
import VueMoment from 'vue-moment';
import VueGallery from 'vue-gallery';

import Card from '@/components/Card.vue';
import BlogCard from '@/components/blog/Card.vue';
import Loading from '@/components/Loading.vue';
import Success from '@/components/SA-Success.vue';
import Error from '@/components/SA-Error.vue';
import Warning from '@/components/SA-Warning.vue';
import SocialContact from '@/components/layout/SocialContact.vue';
import HeroCover from '@/components/HeroCover.vue';

Vue.use(VueProgressBar, {
  color: '#4db6ac',
  failedColor: 'red',
  thickness: '4px',
  transition: {
    speed: '0.5s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
  autoFinish: false
});
Vue.use(VueTypedJs);
Vue.use(VueWow);
Vue.use(VueCarousel);
Vue.use(browserDetect);
Vue.use(VueMoment);

Vue.component('vue-owl-carousel', VueOwlCarousel);
Vue.component('vue-gallery', VueGallery);
Vue.component('app-card', Card);
Vue.component('app-blog-card', BlogCard);
Vue.component('app-loading', Loading);
Vue.component('app-success', Success);
Vue.component('app-warning', Warning);
Vue.component('app-error', Error);
Vue.component('app-social-contact', SocialContact);
Vue.component('app-hero-cover', HeroCover);

Vue.prototype.$axios = axios;
Vue.prototype.$humanize = humanize;

Vue.config.productionTip = false;
Vue.config.silent = true;

Vue.mixin(mixin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
