<template>
  <div id="app">
    <vue-progress-bar />
    <app-header />
      <router-view v-bind:key="$route.fullPath" />
    <keep-alive>
      <app-social-contact />
    </keep-alive>
    <app-footer />
  </div>
</template>

<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
export default {
  components: {
    'app-header': Header,
    'app-footer': Footer,
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    //
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/app.scss';
//
</style>
