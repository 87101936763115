/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue';
import Career from '@/views/Career.vue';
import ContactMe from '@/views/ContactMe.vue';
import Experiences from '@/views/Experiences.vue';
import Portfolio from '@/views/Portfolio.vue';
import References from '@/views/References.vue';
import ViewProject from '@/views/ViewProject.vue';
//
import BlogIndex from '@/views/blog/Index.vue';
import BlogArticle from '@/views/blog/Article.vue';
import BlogCategories from '@/views/blog/Categories.vue';
//
import Error from '@/views/Error.vue';

Vue.use(VueRouter)

const routes = [
  { path: '/',                  name: 'home',             component: Home },
  { path: '/career',            name: 'career',           component: Career },
  { path: '/contact-me',        name: 'contact-me',       component: ContactMe },
  { path: '/experiences',       name: 'experiences',      component: Experiences },
  { path: '/portfolio',         name: 'portfolio',        component: Portfolio },
  { path: '/portfolio/:id',     name: 'view-project',     component: ViewProject },
  { path: '/references',        name: 'references',       component: References },
  { path: '/blog',              name: 'blog',             component: BlogIndex },
  { path: '/blog/category/:id', name: 'blog-categories',  component: BlogIndex },
  { path: '/blog/article/:id',  name: 'blog-article',     component: BlogArticle },
  { path: '*', name: 'Error', component: Error },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
