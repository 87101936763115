<template>
  <div class="contact-me">
    <u-animate-container>
      <div class="cover">
        <div class="parallax-container">
          <div class="parallax">
            <img src="http://www.hivetechnologies.co.uk/wp-content/uploads/2016/02/parallax2-1.jpg" alt="" />
            <div class="upper-layer">
              <div class="centered-v container">
                <u-animate
                  name="fadeInUp"
                  delay=".25s"
                  duration=".75s"
                  :iteration="1"
                  :offset="150"
                  animateClass="animated"
                  :begin="false">
                  <h3>Reach me easily</h3>
                </u-animate>
                <u-animate
                  name="fadeInUp"
                  delay=".5s"
                  duration=".75s"
                  :iteration="1"
                  :offset="150"
                  animateClass="animated"
                  :begin="false">
                  <h6>If you want to contact me for something, I'll be very happy for emailing me below. Be ensure that every email sent me will be read as soon as possible.</h6>
                </u-animate>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col s12 center-align">
            <h6>Kindly, fill your basic information below to get in touch with you faster.</h6>
            <h6>These info will be safe and NEVER will be shared.</h6>
          </div>
          <div class="col s12">
            <form v-on:submit.prevent="formSubmit" id="formSubmit" ref="formSubmit" class="card">
              <div class="progress" v-if="isPosting">
                <div class="indeterminate"></div>
              </div>
              <div class="card-content row">
                <div class="input-field col s12">
                  <i class="material-icons prefix">account_circle</i>
                  <input id="fullname" type="text" class="validate"
                    v-bind="{disabled: isDisabled}"
                    name="fullname" v-model="message.fullname" required />
                  <label for="fullname">Fullname</label>
                </div>
                <div class="input-field col s12">
                  <i class="material-icons prefix">mail</i>
                  <input id="email" type="email" class="validate"
                    v-bind="{disabled: isDisabled}"
                    name="email" v-model="message.email" required />
                  <label for="email">Email</label>
                </div>
                <div class="input-field col s12">
                  <i class="material-icons prefix">phone</i>
                  <input id="phone" type="text" class="validate"
                    v-bind="{disabled: isDisabled}"
                    name="phone" v-model="message.phone" required />
                  <label for="phone">Phone number (to reach you via whatsapp, viber or telegram)</label>
                </div>
                <div class="input-field col s12">
                  <i class="material-icons prefix">subject</i>
                  <input id="title" type="text" class="validate"
                    v-bind="{disabled: isDisabled}"
                    name="title" v-model="message.title" required />
                  <label for="title">Message title</label>
                </div>
                <div class="input-field col s12">
                  <i class="material-icons prefix">message</i>
                  <textarea id="message" class="materialize-textarea validate"
                    v-bind="{disabled: isDisabled}" name="message" v-model="message.message" required></textarea>
                  <label for="message">Your message</label>
                </div>
                <div class="server-response col s12">
                  <div class="card">
                    <div class="card-content center-align teal" v-text="responseText" v-if="responseText"></div>
                  </div>
                </div>
                <div class="center-align col s12">
                  <button type="submit"
                    v-bind="{disabled: isDisabled}"
                    id="formSubmit" class="btn btn-success waves-effect">
                    <i class="material-icons">send</i>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col s12">
            <div class="card">
              <div class="card-content">
                <p class="center-align">The power of imagination makes us infinite.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </u-animate-container>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
export default {
  props: [],
  components: {
    //
  },
  data() {
    return {
      message: {},
      isDisabled: false,
      isPosting: false,
      isResponsed: false,
      responseText: '',
    };
  },
  computed: {
    //
  },
  methods: {
    formSubmit() {
      let _this = this;
      //
      this.startProgress();
      this.isDisabled = true;
      this.isPosting = true;
      this.responseText = '';
      //
      this.$axios.post(this.api, {
        type: 'drop-message',
        ...this.message
      })
      .then(response => {
        let data = response.data;
        //console.log(data);
        if (data.status === 'ok') {
          this.message = {};
          this.responseText = 'Your message has been sent. Thank you.';
        }
        else {
          this.responseText = 'An error occured while sending your message. Please retry again.';
        }
      })
      .catch(() => {
        this.responseText = 'An error occured while sending your message. Please retry again.';
      })
      .finally(() => {
        this.isPosting = false;
        this.isDisabled = false;
        this.finishProgress();
      });
    },
  },
  created() {
    //
  },
  mounted() {
    this.finishProgress();
    let parallax = document.querySelectorAll('.parallax');
    M.Parallax.init(parallax);
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.cover {
  .parallax-container {
    height: 333px;
    //
    .parallax {
      .upper-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 15px;
        background-color: rgba(0, 0, 0, .5);
        z-index: 2;
        color: #fff;
        text-align: center;
        //
        .centered-v {
          position: relative;
          top: 30%;
          @include transform(translateY(-30%));
          //
          .typed-element {
            text-align: center;
            display: block;
          }
        }
      }
    }
  }
}

.server-response {
  color: #fff;
}
</style>
