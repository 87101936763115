<template>
  <div class="portfolio height-fixer">
    <app-loading v-if="isLoading" />
    <app-portfolio v-bind="{categories: categories, data: portfolio}" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import Portfolio from '@/components/home/Portfolio.vue';
export default {
  props: [],
  components: {
    'app-portfolio': Portfolio,
  },
  data() {
    return {
      portfolio: [],
      categories: [],
      isLoading: true,
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    let _this = this;
    this.$axios.get(this.api, {
      params: {
        type: 'get-portfolio',
      }
    })
    .then(response => {
      let data = response.data;
      this.portfolio = data.portfolio;
      this.categories = data.portfolio_categories;
      this.isLoading = false;
      this.finishProgress();
    })
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.portfolio {
  padding: 35px 0;
}
</style>
