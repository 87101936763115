<template>
  <div class="references height-fixer">
    <app-loading v-if="isLoading" />
    <app-references v-bind="{data: references}" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import References from '@/components/home/References.vue';
export default {
  props: [],
  components: {
    'app-references': References,
  },
  data() {
    return {
      references: [],
      isLoading: [],
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    let _this = this;
    this.$axios.get(this.api, {
      params: {
        type: 'get-references',
      }
    })
    .then(response => {
      let data = response.data;
      this.references = data.references;
      this.isLoading = false;
      this.finishProgress();
    })
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.references {
  padding: 35px 0;
}
</style>
