var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blog-article height-fixer"},[_c('u-animate-container',[_c('app-hero-cover',_vm._b({},'app-hero-cover',{
        title: _vm.title,
        p: '',
        image: '/assets/blog-header-2.jpg',
        isLoading: _vm.isLoading,
        isRTL: true,
      },false)),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col xl8 l8 m12 s12"},[(_vm.isLoading)?_c('app-loading'):_c('u-animate',{attrs:{"name":"fadeInUp","delay":"0s","duration":"1s","iteration":1,"offset":0,"animateClass":"animated","begin":false}},[_c('app-blog-card',_vm._b({},'app-blog-card',{data: _vm.article},false))],1)],1),_c('div',{staticClass:"col xl4 l4 m12 s12"},[_c('u-animate',{attrs:{"name":"fadeInUp","delay":"0s","duration":"1s","iteration":1,"offset":0,"animateClass":"animated","begin":true}},[_c('router-link',{staticClass:"btn-floating- btn-large waves-effect waves-light red",staticStyle:{"display":"block","margin-top":"7px"},attrs:{"to":"/blog"}},[_c('i',{staticClass:"material-icons"},[_vm._v("arrow_back")])])],1),_c('app-sidebar',_vm._b({},'app-sidebar',{
              categories: _vm.categories,
              isLoading: _vm.isLoading,
            },false)),(_vm.isLoading === false)?_c('app-likes',_vm._b({},'app-likes',{likes: _vm.likes, liked_by_user: _vm.liked_by_user, id: _vm.id},false)):_vm._e()],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }