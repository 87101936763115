var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blog-index height-fixer"},[_c('u-animate-container',[_c('app-hero-cover',_vm._b({},'app-hero-cover',{
        title: _vm.heroCoverTitle,
        p: _vm.heroCoverP,
        image: '/assets/blog-header-2.jpg',
        isLoading: _vm.isLoading,
      },false)),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col xl8 l8 m8 s12"},[_c('app-articles',_vm._b({},'app-articles',{
              articles: _vm.articles,
              isLoading: _vm.isLoading,
              isReachEnd: _vm.isReachEnd,
            },false))],1),_c('div',{staticClass:"col xl4 l4 m4 s12"},[_c('app-sidebar',_vm._b({},'app-sidebar',{
              categories: _vm.categories,
              isLoading: _vm.isLoading,
            },false))],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }