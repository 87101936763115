<template>
  <div class="height-fixer">
    <div class="home">
      <div class="loading" v-if="isLoading">
        <app-loading class="loading-spinner" />
      </div>
      <div class="container-fluid" v-else>
        <app-cover />
        <app-me />
        <app-quote v-bind="{
          header: `Become your own success story, not someone else's.`,
          text: `Don’t just exist. Do something meaningful with your life. Discover a problem and fix it.`,
        }" />
        <app-career v-bind="{data: career}" />
        <app-experiences v-bind="{data: experiences}" />
        <app-portfolio v-bind="{categories: portfolioCategories, data: portfolio}" />
        <app-references v-bind="{data: references}" />
        <app-slider v-bind="{data: slider}" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
// @ is an alias to /src
import Cover from '@/components/home/Cover.vue';
import Me from '@/components/home/Me.vue';
import Portfolio from '@/components/home/Portfolio.vue';
import Career from '@/components/home/Career.vue';
import References from "@/components/home/References.vue";
import Slider from '@/components/home/Slider.vue';
import Experiences from '@/components/home/Experiences.vue';
import Quote from '@/components/layout/Quote.vue';
//
export default {
  name: 'home',
  components: {
    'app-cover': Cover,
    'app-me': Me,
    'app-portfolio': Portfolio,
    'app-career': Career,
    'app-references': References,
    'app-slider': Slider,
    'app-experiences': Experiences,
    'app-quote': Quote,
  },
  data() {
    return {
      isLoading: true,
      experiences: [],
      portfolio: [],
      portfolioCategories: [],
      references: [],
      career: [],
      slider: [],
    };
  },
  computed: {
    //
  },
  created() {
    let _this = this;
    //
    this.$axios.get(this.api, {
      params: {
        type: 'get-home'
      }
    })
    .then(response => {
      let data = response.data;
      this.experiences = data.experiences;
      this.portfolio = data.portfolio;
      this.portfolioCategories = data.portfolio_categories;
      this.references = data.references;
      this.career = data.career;
      this.slider = data.slider;
      this.isLoading = false;
      this.finishProgress();
    })
  },
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.loading {
  .loading-spinner {
    position: relative;
    top: 250px;
  }
}
</style>