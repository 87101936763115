<template>
  <u-animate-container>
    <div
      class="hero-cover z-depth-3"
      v-bind="{'data-image': image}"
      ref="hero_cover">
      <div class="hero-container container">
        <app-loading v-if="isLoading" />
        <div v-else>
          <u-animate
            name="fadeInUp"
            :delay="`.25s`"
            duration="1s"
            :iteration="1"
            :offset="0"
            animateClass="animated"
            :begin="false">
            <h2 :class="{'is-rtl': isRTL !== undefined}">{{ title }}</h2>
          </u-animate>
          <u-animate
            name="fadeInUp"
            :delay="`.75s`"
            duration="1s"
            :iteration="1"
            :offset="0"
            animateClass="animated"
            :begin="false">
            <h6>{{ p }}</h6>
          </u-animate>
        </div>
      </div>
    </div>
  </u-animate-container>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
export default {
  props: ['title', 'p', 'image', 'isLoading', 'isRTL'],
  components: {
    //
  },
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    let hero_cover = this.$refs.hero_cover;
    hero_cover.style.backgroundImage = `url(${this.image})`;
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.is-rtl {
  direction: rtl;
}
//
.hero-cover {
  text-align: center;
  color: #fff;
  height: 300px;
  background: center center no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 25px;
  //
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(#272727, .67);
  }
  //
  .hero-container {
    position: relative;
    top: 50%;
    z-index: 2;
    @include transform(translateY(-50%));
    //
    h2 {
      margin-top: 0;
    }
  }
}
</style>
