<template>
  <footer class="page-footer teal darken-4 z-depth-5">
    <div class="container">
      <div class="row">
        <div class="col l7 m6 s12">
          <h5 class="white-text">Reaching the end does not mean that it is the end, maybe it is another big start.</h5>
          <p class="grey-text text-lighten-4">Life is 10% what happens to you and 90% how you react to it.</p>
        </div>
        <div class="col l5 m6 s12">
          <h5 class="white-text">Sitemap</h5>
          <div class="col s5">
            <ul>
              <li>
                <router-link class="grey-text text-lighten-3" to='/blog'>Blog</router-link>
              </li>
              <li>
                <router-link class="grey-text text-lighten-3" to='/career'>Career</router-link>
              </li>
              <li>
                <router-link class="grey-text text-lighten-3" to='/experiences'>Experiences</router-link>
              </li>
            </ul>
          </div>
          <div class="col s7">
            <ul>
              <li>
                <router-link class="grey-text text-lighten-3" to='/portfolio'>Portfolio</router-link>
              </li>
              <li>
                <router-link class="grey-text text-lighten-3" to='/references'>References</router-link>
              </li>
              <li>
                <router-link class="grey-text text-lighten-3" to='/contact-me'>Drop me a message</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <div class="container center-align">
        <span>© {{ year }} Made with love by me</span>
        <!-- <a class="grey-text text-lighten-4 right" href="javascript://">More Links</a> -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_includes.scss';
//
</style>
