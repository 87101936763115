<template>
  <div class="view-project height-fixer">
    <u-animate-container>
      <div class="project-cover z-depth-2">
        <div class="center-align container">
          <app-loading v-if="isLoading" style="margin: 0 auto" />
          <div v-else>
            <u-animate
              name="fadeInUp"
              :delay="`0s`"
              duration="1s"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="false">
              <h2>{{ title }}</h2>
            </u-animate>
            <u-animate
              name="fadeInUp"
              :delay="`.25s`"
              duration="1s"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="false">
              <h6>A project for {{ owner }}</h6>
            </u-animate>
          </div>
        </div>
      </div>
      <div class="container project-content" v-if="isLoading === false">
        <u-animate
          name="fadeInUp"
          :delay="`0s`"
          duration="1s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false">
          <div class="card">
            <div class="card-content">
              <div class="row">
                <div class="col l8 s12">
                  <img :src="cover" v-if="project.project_cover"
                    class='z-depth-1' id="mainImage" alt="project-slider" ref="project_slider" />
                  <div class="project-slider" v-if="images.length > 0">
                    <hr />
                    <vue-gallery
                      v-bind="{images: bindedImages, index: index}" v-on:close="index = null"
                    />
                    <div
                      v-for="(img, i) in images"
                      v-bind:key="i"
                      class="col xl3 l4 m3 s6">
                      <img
                        v-on:click="index = i"
                        :src="storage(`portfolio/${img}`)"
                        class="z-depth-2 image"
                        alt="Image" />
                    </div>
                    <div class="clearfix"></div>
                    <hr />
                    <!-- <vue-owl-carousel v-bind="{items: 5, responsive: responsive, center: true}">
                    </vue-owl-carousel> -->
                  </div>
                  <article v-html="details"></article>
                  <hr />
                </div>
                <div class="col l4 s12">
                  <img v-bind="{src: image, alt: title}" class="z-depth-2" />
                  <ul>
                    <li>
                      <hr />
                      <div class="card pink darkrn-3 visits">
                        <div class="card-content">
                          <p v-if="visits === 1">You are the first one who sees this project.</p>
                          <p v-else>This project has been seen for <b>{{ visits }}</b> times.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <span>Date: {{ date | moment('MMMM Do, YYYY') }}</span>
                    </li>
                    <li>
                      <span>Type: </span>
                      <span>{{ category_title }}</span>
                    </li>
                    <li>
                      <span>Owner: </span>
                      <span>{{ owner }}</span>
                    </li>
                    <li>
                      <span>Link: </span>
                      <a :href="link" target="_blank">{{ title }}</a>
                    </li>
                    <li>
                      <span>Building tools: </span>
                      <span>[{{ building_tools.join(', ') }}]</span>
                    </li>
                    <li>
                      <hr />
                      <a
                        @click="actionLike()"
                        class="btn-floating btn-small waves-effect waves-light red"
                        v-show="liked_by_user === false">
                        <i class="material-icons">exposure_plus_1</i>
                      </a>
                      <a
                        @click="actionLike()"
                        ref="liked_btn"
                        class="btn-floating btn-small waves-effect waves-light red pulse"
                        v-show="liked_by_user">
                        <i class="material-icons">star</i>
                      </a>
                      <span v-if="likes > 0">{{ like_text }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </u-animate>
      </div>
    </u-animate-container>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
export default {
  props: [],
  components: {
    //
  },
  data() {
    return {
      isLoading: true,
      project: {},
      category: {},
      visits: 0,
      index: null,
      likes: [],
      liked_by_user: false,
    };
  },
  computed: {
    id_project() {
      return this.$route.params.id;
    },
    title() {
      return this.project.project_title;
    },
    date() {
      let date = this.project.project_date * 1000;
      return new Date(date);
    },
    link() {
      return this.project.project_link;
    },
    owner() {
      return this.project.project_owner;
    },
    id_category() {
      return this.project.project_id_category;
    },
    cover() {
      return this.storage(`portfolio/${this.project.project_cover}`);
    },
    image() {
      return this.storage(`portfolio/${this.project.project_image}`);
    },
    images() {
      return JSON.parse(this.project.project_images);
    },
    bindedImages() {
      let images = this.images;
      return images.map(image => this.storage(`portfolio/${image}`));
    },
    firstImageOfImages() {
      return this.storage(`portfolio/${this.images[0]}`);
    },
    building_tools() {
      return JSON.parse(this.project.project_building_tools);
    },
    brief() {
      return this.project.project_brief;
    },
    details() {
      return this.project.project_details;
    },
    //
    category_title() {
      return this.category.portfolio_category_title;
    },
    //
    like_text() {
      let string = '',
        stringSuffix = 'loved this project';
      if (this.liked_by_user === false) {
        string =  `${this.likes} ${this.likes > 1 ? 'people' : 'person'} ${stringSuffix}`;
      }
      else {
        let likesExceptYou = this.likes - 1;
        string = `You ${likesExceptYou > 0 ? `and ${likesExceptYou} ${likesExceptYou > 1 ? `people` : `person`}` : ''} ${stringSuffix}`;
      }
      return string;
    },
  },
  methods: {
    actionLike() {
      this.logLike(`/portfolio/${this.id_project}`, response => {
        if (response.status) {
          this.liked_by_user = response.liked_by_user;
          this.likes = response.likes;
        }
      })
    },
  },
  created() {
    //
  },
  mounted() {
    let _this = this;
    this.$axios.get(this.api, {
      params: {
        type: 'get-project',
        id_project: this.id_project,
      }
    })
    .then(response => {
      let data = response.data;
      this.project = data.project[0];
      this.category = data.category[0];
      this.visits = data.visits;
      this.isLoading = false;
      this.likes = data.likes;
      this.liked_by_user = data.liked_by_user;
      this.finishProgress();
      // console.log(data);
    })
    .catch(() => {})
    .finally(() => {});
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.view-project {
  .project-cover {
    background: center center url('/assets/icon-seamless.png');
    height: 300px;
    position: relative;
    color: #fff;
    //
    .container {
      $top: 45%;
      position: relative;
      z-index: 1;
      top: $top;
      @include transform(translateY(-$top));
      //
      h2, h5, h6 {
        margin: 0;
      }
    }
    //
    &::before {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background-color: rgba($main_color, .75);
    }
  }
  //
  .project-content {
    margin: 33px auto;
    //
    img {
      width: 100%;
      display: block;
    }
    //
    ul {
      li {
        padding: 5px 0;
        //
        .btn-floating {
          margin-right: 5px;
        }
      }
    }
    //
    article {
      margin: 20px 0;
    }
    //
    .project-slider {
      margin-bottom: 20px;
      //
      img:not(#mainImage) {
        padding: 0px;
        margin: 8px 0;
        cursor: pointer;
        @include radius(4px);
      }
    }
  }
  //
  .visits {
    color: #fff;
  }
}
</style>
