<template>
  <div class="blog-articles">
    <u-animate-container>
      <app-loading v-if="isLoading" />
      <div class="articles" v-else-if="articles.length > 0">
        <div class="article" v-for="(article, i) in articles" v-bind:key="i">
          <u-animate
            name="fadeInUp"
            :delay="`${duration(i, articles.length)}s`"
            duration="1s"
            :iteration="1"
            :offset="225"
            animateClass="animated"
            :begin="true">
            <!-- this value is true because if it is false: it will affect error in wow animation -->
            <app-blog-card
              v-bind="{data: article, briefing: true,}"
            />
          </u-animate>
        </div>
        <app-loading v-if="isReachEnd === false" :size="45" />
      </div>
      <div v-else>
        <h4 class="center-align">No articles in this section</h4>
      </div>
    </u-animate-container>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
export default {
  props: ['articles', 'isLoading', 'isReachEnd'],
  components: {
    //
  },
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_includes.scss';
//
</style>
