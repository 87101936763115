<template>
  <div class="cover">
    <div class="parallax-container">
      <div class="parallax">
        <img src="@/assets/cover.jpg" />
        <div class="upper-layer">
          <div class="centered-v">
            <vue-typed-js
              v-bind="{
                strings: headers, startDelay: 2000, typeSpeed: 25, cursorChar: '&nbsp;&nbsp;>_'
              }">
              <h2><span class="typing"></span></h2>
            </vue-typed-js>
            <vue-typed-js
              v-bind="{
                strings: phrases, startDelay: 4000, typeSpeed: 25, cursorChar: '&nbsp;&nbsp;>_'
              }">
              <h6><span class="typing"></span></h6>
            </vue-typed-js>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  data() {
    return {
      headers: ['Coding !== just a career'],
      phrases: ['Coding is that love which begins when you run your first code successfully and you never stop coding anymore;'],
    };
  },
  computed: {
    cover() {
      return '/assets/cover.png';
    },
  },
  created() {
    //
  },
  mounted() {
    let parallax = document.querySelectorAll('.parallax');
    M.Parallax.init(parallax);
    //
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_includes.scss';
//
.parallax-container {
  height: 600px;
  overflow: hidden;
  //
  .parallax {
    z-index: 1;
    //
    img {
      opacity: 1;
    }
    //
    .upper-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 15px;
      background-color: rgba(0, 0, 0, .75);
      z-index: 2;
      color: #fff;
      text-align: center;
      //
      .centered-v {
        position: relative;
        top: 30%;
        @include transform(translateY(-30%));
        //
        .typed-element {
          text-align: center;
          display: block;
        }
      }
    }
  }
}
</style>