<template>
  <div class="experience-card">
    <div class="experience-item z-depth-3">
      <div class="img-container">
        <img v-bind="{src: image(item), alt: ''}" />
      </div>
      <!-- <progress-bar
        class="progress-bar"
        type="line"
        v-bind="{options: baseOptions, 'data-value': value(item)}"
      /> -->
      <h5 class="center-align">{{ title(item) }}</h5>
      <p class="center-align">{{ hint(item) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  components: {
    //
  },
  data() {
    return {
      baseOptions: {
        color: '#d84315',
        trailColor: '#9e9e9e',
        strokeWidth: 1.5,
        trailWidth: .20,
        easing: 'bounce',
        duration: 1500,
      },
    };
  },
  computed: {
    //
  },
  methods: {
    image(item) {
      return this.storage(`experiences/${item.experience_image}`);
    },
    title(item) {
      return item.experience_title;
    },
    hint(item) {
      return item.experience_hint;
    },
    value(item) {
      return item.experience_value;
    },
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.experience-item {
  margin-bottom: 25px;
  min-height: 360px;
  border: 1px solid silver;
  background-color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  @include transitionDuration(.2s);
  @include radius(5px);
  //
  &:hover {
    @include transform(scale(1.04));
  }
  //
  .img-container {
    min-height: 100px;
    margin: 10px auto;
    position: relative;
    height: 100px;
    //
    img {
      padding: 0 5px;
      display: block;
      margin: 0 auto;
      max-width: 100%;
      max-height: 100px;
      position: relative;
      top: 50%;
      @include transform(translateY(-50%));
    }
  }
  //
  //
  .progress-bar {
    margin: 20px 0;
  }
}
</style>
