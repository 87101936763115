<template>
  <div class="experiences height-fixer">
    <app-loading v-if="isLoading" />
    <app-experiences v-bind="{data: experiences}" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import Experiences from '@/components/home/Experiences.vue';
export default {
  props: [],
  components: {
    'app-experiences': Experiences,
  },
  data() {
    return {
      experiences: [],
      isLoading: true,
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    let _this = this;
    this.$axios.get(this.api, {
      params: {
        type: 'get-experiences',
      }
    })
    .then(response => {
      let data = response.data;
      this.experiences = data.experiences;
      this.isLoading = false;
      this.finishProgress();
    })
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.experiences {
  padding: 35px 0;
}
</style>
