<template>
  <div class="career container">
    <u-animate-container>
      <div class="center-align">
        <u-animate
          name="fadeInUp"
          delay="0s"
          duration="1s"
          :iteration="1"
          :offset="180"
          animateClass="animated"
          :begin="false">
          <h2>Career</h2>
        </u-animate>
        <u-animate
          name="fadeInUp"
          delay="0s"
          duration="1s"
          :iteration="1"
          :offset="180"
          animateClass="animated"
          :begin="false">
          <h6>It does not matter how slowly you go as long as you do not stop.</h6>
        </u-animate>
      </div>

      <u-animate>
        <div class="timeline">
          <u-animate
            v-for="(item, i) in data"
            v-bind:key="i"
            name="fadeInUp"
            delay="0s"
            duration="1s"
            :iteration="1"
            :offset="200"
            animateClass="animated"
            :begin="false">
            <app-career-card v-bind="{item: item}" />
          </u-animate>
        </div>
      </u-animate>
      
    </u-animate-container>
  </div>
</template>

<script>
import CareerCard from '@/components/home/CareerCard.vue';
export default {
  components: {
    'app-career-card': CareerCard,
  },
  props: ['data'],
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
.career {
  padding: 35px 0;
  position: relative;
  //
}
</style>
