<template>
  <div class="blog-article height-fixer">
    <u-animate-container>
      <app-hero-cover
        v-bind="{
          title: title,
          p: '',
          image: '/assets/blog-header-2.jpg',
          isLoading: isLoading,
          isRTL: true,
        }"
      />
      <div class="container">
        <div class="row">
          <div class="col xl8 l8 m12 s12">
            <app-loading v-if="isLoading" />
            <u-animate v-else
              name="fadeInUp"
              :delay="`0s`"
              :duration="`1s`"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="false">
              <app-blog-card
                v-bind="{data: article}"
              />
            </u-animate>
          </div>
          <div class="col xl4 l4 m12 s12">
            <u-animate
              name="fadeInUp"
              :delay="`0s`"
              :duration="`1s`"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="true">
              <router-link
                to="/blog"
                style="display: block; margin-top: 7px;"
                class="btn-floating- btn-large waves-effect waves-light red">
                <i class="material-icons">arrow_back</i>
              </router-link>
            </u-animate>
            <app-sidebar
              v-bind="{
                categories: categories,
                isLoading: isLoading,
              }"
            />
            <app-likes v-if="isLoading === false" v-bind="{likes, liked_by_user, id}" />
          </div>
        </div>
      </div>
    </u-animate-container>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import Sidebar from '@/components/blog/Sidebar.vue';
import Likes from '@/components/blog/Likes.vue';
export default {
  props: [],
  components: {
    'app-sidebar': Sidebar,
    'app-likes': Likes,
    //
  },
  data() {
    return {
      isLoading: true,
      article: {},
      categories: [], // just to pass it to sidebar
      visits: 0,
      likes: [],
      liked_by_user: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    title() {
      return this.article.article !== undefined ? this.article.article.blog_title : '';
    },
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    let _this = this;
    this.isLoading = true;
    //
    this.$axios.get(this.api, {
      params: {
        type: 'get-blog',
        id_blog: this.id,
      }
    })
    .then(response => {
      this.finishProgress();
      this.isLoading = false;
      //
      let data = response.data;
      this.article = data.articles[0];
      this.categories = data.categories;
      this.visits = data.articles[0].visits;
      this.likes = data.likes;
      this.liked_by_user = data.liked_by_user;
      //
      // console.log(this.obj);
    })
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_includes.scss';
//
</style>
