<template>

  <div :class="`card sticky-action hoverable category-${id_category}`">
    <div class="card-image waves-effect waves-block waves-light">
      <img class="activator" :src="image" />
    </div>
    <!-- <div class="card-content">
      <span class="card-title activator grey-text text-darken-4">
        <span>{{ title }}</span>
        <i class="material-icons right">more_vert</i>
      </span>
    </div> -->
    <div class="card-reveal">
      <div class="card-title grey-text text-darken-4">
        <h6>
          <span style="font-weight: bold;">{{ title }}</span>
          <i class="material-icons right">close</i>
        </h6>
      </div>
      <p>{{ brief }}</p>
    </div>
    <div class="card-action">
      <router-link :to="`/portfolio/${id}`">
        See project details
      </router-link>
    </div>
  </div>

</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      //
    };
  },
  computed: {
    image() {
      return this.storage(`portfolio/${this.data.project_image}`);
    },
    title() {
      return this.data.project_title;
    },
    details() {
      return this.data.project_details;
    },
    id() {
      return this.data.id_project;
    },
    id_category() {
      return this.data.project_id_category;
    },
    brief() {
      return `A project for ${this.data.project_owner}`;
    },
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
</style>
