<template>
  <div class="blog-index height-fixer">
    <u-animate-container>
      <app-hero-cover
        v-bind="{
          title: heroCoverTitle,
          p: heroCoverP,
          image: '/assets/blog-header-2.jpg',
          isLoading: isLoading,
        }"
      />
      <div class="container">
        <div class="row">
          <div class="col xl8 l8 m8 s12">
            <app-articles
              v-bind="{
                articles: articles,
                isLoading: isLoading,
                isReachEnd: isReachEnd,
              }"
            />
          </div>
          <div class="col xl4 l4 m4 s12">
            <app-sidebar
              v-bind="{
                categories: categories,
                isLoading: isLoading,
              }"
            />
          </div>
        </div>
      </div>
    </u-animate-container>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import Articles from '@/components/blog/Articles.vue';
import Sidebar from '@/components/blog/Sidebar.vue';
export default {
  props: [],
  components: {
    'app-sidebar': Sidebar,
    'app-articles': Articles,
  },
  data() {
    return {
      articles: [],
      categories: [],
      activeCategory: {},
      isLoading: true,
      isLoadingMoreArticles: false,
      isReachEnd: false,
    };
  },
  computed: {
    id_category() {
      let id_category = this.$route.params.id;
      return id_category === undefined ? null : id_category;
    },
    heroCoverTitle() {
      return this.id_category === null ? 'Blog' : this.activeCategory.blog_category_title;
    },
    heroCoverP() {
      return this.id_category === null ? 'Don’t focus on having a great blog. Focus on producing a blog that’s great for your readers.' : '';
    },
  },
  methods: {
    loadMoreArticles() {
      if (this.isReachEnd === false) {
        let st = window.scrollY,
          socialContact = document.querySelector('.social-contact').offsetTop,
          windowHeight = window.innerHeight;
        //
        if (((st + windowHeight) >= socialContact) && this.isLoadingMoreArticles === false) {
          this.isLoadingMoreArticles = true;
          //
          let lastBlog = document.querySelectorAll('.article');
          lastBlog = lastBlog[lastBlog.length - 1].getElementsByTagName('div')[2];
          let
            id_blog = lastBlog.getAttribute('data-id-blog'),
            id_category = lastBlog.getAttribute('data-id-category');
          // console.log(id_category);
          //
          this.$axios.get(this.api, {
            params: {
              type: 'get-blog',
              // limit: 1,
              last_id_blog: id_blog,
              id_category: this.id_category,
            }
          })
          .then(response => {
            let data = response.data;
            this.articles = this.articles.concat(data.articles);
            this.isLoadingMoreArticles = false;
            if (data.articles.length === 0) {
              this.isReachEnd = true;
            }
            // re-assign the this.articles instead of just apply .concat()
            // console.log(data.articles);
          })
        }
      }
    },
    //
  },
  created() {
    window.addEventListener('scroll', this.loadMoreArticles);
    //
  },
  mounted() {
    let _this = this;
    //
    this.isLoading = true;
    this.$axios.get(this.api, {
      params: {
        type: 'get-blog',
        id_category: this.id_category,
      }
    })
    .then(response => {
      this.finishProgress();
      let data = response.data;
      //
      this.isLoading = false;
      this.categories = data.categories;
      this.articles = data.articles;
      // console.log(data.articles);
      if (this.id_category !== undefined) {
        this.categories.forEach((v, i) => {
          if (v.id_blog_category === this.id_category) {
            this.activeCategory = v;
          }
        });
      }
      //
      if (data.articles.length === 0 || data.articles.length < 10) {
        this.isReachEnd = true;
      }
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.loadMoreArticles);
    //
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_includes.scss';
//
</style>
