<template>
  <div class="timeline-card-container">
    <div class="timeline-card-body z-depth-2 hoverable">
      <div class="timeline-card-header">
        <h5>{{ title }}</h5>
        <div>at {{ company }} in {{ companyLocation }}</div>
      </div>
      <div class="timeline-card-content row">
        <div class="col m12 xl6">
          <div class="timeline-date">
            <i class="material-icons">access_time</i>
            <span>{{ dateStart | moment('MMMM Do YYYY') }}</span>
            <span> .. until .. </span>
            <span>{{ dateEnd | moment('MMMM Do YYYY') }}</span>
          </div>
          <article v-html="details"></article>
          <div class="links">
            <span>Link: </span>
            <a :href="link" target="_blank">{{ company }}</a>
          </div>
          <div class="tags">
            <span>Tags: [</span>
            <span v-for="(tag, i) in tags" v-bind:key="i">{{ tag }}<span v-if="(i + 1) !== tags.length">, </span>
            </span>
            <span>]</span>
          </div>
        </div>
        <div class="col m12 xl6">
          <img
            :src="image"
            class="timeline-main-image"
            ref="timeline_main_image"
            alt="Img"
          />
          <carousel class="timeline-carousel"
            v-if="images.length > 0"
            v-bind="{
              perPage: 6,
              scrollPerPage: false,
              paginationEnabled: false,
              navigationEnabled: true
            }">
            <slide class="main-slide">
              <img
                v-on:click="slideChanger(image)"
                :src="image"
                alt="Img"
              />
            </slide>
            <slide v-for="(subimage, i) in images" v-bind:key="i">
              <img
                v-on:click="slideChanger(storage(`career/${subimage}`))"
                v-bind="{src: storage(`career/${subimage}`)}"
                alt="Img"
              />
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data() {
    return {
      //
    };
  },
  computed: {
    mainImage() {
      return this.$refs.timeline_main_image;
    },
    title() {
      return this.item.career_title;
    },
    company() {
      return this.item.career_company;
    },
    companyLocation() {
      return this.item.career_company_location;
    },
    dateStart() {
      let time = this.item.career_date_start * 1000;
      return new Date(time);
    },
    dateEnd() {
      let time = this.item.career_date_end * 1000;
      return new Date(time);
    },
    image() {
      return this.storage(`career/${this.item.career_image}`);
    },
    images() {
      return JSON.parse(this.item.career_images);
    },
    details() {
      return this.item.career_details;
    },
    link() {
      return this.item.career_link;
    },
    tags() {
      return JSON.parse(this.item.career_tags);
    },
  },
  methods: {
    slideChanger(img) {
      this.mainImage.src = img;
    },
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" >
@import '@/assets/scss/_includes.scss';
$color: #00695c;
$card_thickness: 1px;
$base_cursor_top: 25px;
$radius: 8px;
$left_position: 5%;
//
%padding {
  padding: 15px;
  padding-left: 33px;
}
//
.timeline {
  //border: 1px solid $color;
  //padding: 5px;
  position: relative;
  //
  &::before {
    content: '';
    position: absolute;
    background-color: $color;
    width: 4px;
    height: 100%;
    top: 0;
    left: $left_position;
    @include transform(translate(5%, 0));
  }
  //
  .timeline-card-container {
    padding: 15px;
    padding-left: 87px;
    padding-top: 10px;
    position: relative;
    //
    &::before {
      position: absolute;
      content: '';
      top: $base_cursor_top + 11px;
      left: calc(#{$left_position} - 8px);
      border: 1px solid $color;
      background-color: $color;
      @include radius(50%);
      @include square(20px);
    }
    //
    .timeline-card-body {
      border: $card_thickness solid $color;
      position: relative;
      background-color: #fff;
      @include radius($radius);
      //
      &::before {
        position: absolute;
        top: $base_cursor_top + 0px;
        left: -11px;
        content: '';
        border: $card_thickness solid $color;
        border-top: 0 #fff;
        border-right: 0 #fff;
        background-color: $color;
        @include square(20px);
        @include transform(rotate(45deg));
      }
      //
      .timeline-card-header {
        font-weight: bold;
        margin: 0;
        background-color: $color;
        color: #fff;
        @include radius($radius - 3px, 'tl-tr');
        @extend %padding;
      }
      //
      .timeline-card-content {
        margin-bottom: 0;
        @extend %padding;
        //
        article {
          color: #847171;
        }
        //
        i {
          font-size: 21px;
          margin: 0 10px 0 0;
        }
        //
        .timeline-main-image {
          display: block;
          width: 100%;
          margin-top: 10px;
        }
        //
        .timeline-carousel {
          margin: 15px 0;
          //
          img {
            display: block;
            width: 100%;
            padding: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
