<template>
  <div class="blog-sidebar">
    <u-animate-container>
      <u-animate
        name="fadeInUp"
        :delay="`0s`"
        duration="1s"
        :iteration="1"
        :offset="100"
        animateClass="animated"
        :begin="false">
        <app-categories
          v-bind="{
            data: categories,
            isLoading: isLoading,
          }"
        />
      </u-animate>
      <u-animate
        name="fadeInUp"
        :delay="`0s`"
        duration="1s"
        :iteration="1"
        :offset="100"
        animateClass="animated"
        :begin="false">
        <app-hint />
      </u-animate>
    </u-animate-container>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import Categories from '@/components/blog/Categories.vue';
import Hint from '@/components/blog/Hint.vue';
export default {
  props: ['categories', 'isLoading'],
  components: {
    'app-categories': Categories,
    'app-hint': Hint,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
</style>
